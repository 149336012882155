import React from 'react';
import ReactDOM from 'react-dom/client';

// Import translations
import i18n from './i18n';

// Import fonts
import './assets/fonts/fonts';

// Import vendors
import './vendors/vendor_styles';

// Import firebase initialization
import './helpers/firebase.js';

// Import root component
const Root = React.lazy(() => import('Root'));

if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
}

const root = ReactDOM.createRoot(document.getElementById(
	'root'
) as HTMLElement);

i18n.on('initialized', () => {
	root.render(
		<React.Suspense fallback={null}>
			<Root />
		</React.Suspense>
	);
});

if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/firebase-messaging-sw.js')
		.then(function(registration) {
			console.log('Registration successful, scope is:', registration.scope);
		})
		.catch(function(err) {
			console.log('Service worker registration failed, error:', err);
		});
}
