export const LOCAL_STORAGE_LANGUAGE = '_lng';

export const projectLanguages = process.env.REACT_APP_LANGUAGES.split(',');

export const pathnameLngDetector = {
	name: 'pathname',
	lookup({ supported = [] }) {
		const { pathname } = window.location;
		const lng = pathname.split('/')[1];
		const isSupported = supported.includes(lng);
		return isSupported ? lng : undefined;
	}
};
